const baseUrl = process.env.NEXT_PUBLIC_ASSETS_IMAGEKIT as string;

const ICONS = {
  ICON_WHATSAPP: `${baseUrl}/ico-whatsapp.svg`,
  ICON_VERIFIED: `${baseUrl}/icon-verified.svg`,
  ICON_FACEBOOK: `${baseUrl}/icon-facebook.svg`,
  ICON_YOUTUBE: `${baseUrl}/icon-youtube.svg`,
  ICON_TWITTER: `${baseUrl}/icon-twitter.svg`,
  ICON_INSTAGRAM: `${baseUrl}/icon-instagram.svg`,
  ICON_MENU_1: `${baseUrl}/icon-1.svg`,
  ICON_MENU_2: `${baseUrl}/icon-2.svg`,
  ICON_MENU_3: `${baseUrl}/icon-3.svg`,
  ICON_MENU_4: `${baseUrl}/icon-4.svg`,
  ICON_MENU_5: `${baseUrl}/icon-5.svg`,
  ICON_MENU_6: `${baseUrl}/icon-6.svg`,
  ICON_MENU_7: `${baseUrl}/icon-7.svg`,
  ICON_MENU_8: `${baseUrl}/icon-8.svg`,
  ICON_MENU_9: `${baseUrl}/icon-9.svg`,
  ICON_MENU_10: `${baseUrl}/icon-10.svg`,
  ICON_MENU_11: `${baseUrl}/icon-11.svg`,
  ICON_MENU_12: `${baseUrl}/icon-12.svg`,
  ICON_POWERED_TELKOM: `${baseUrl}/icon-powered-telkom@2x.png`,
  ICON_BUILDING: `${baseUrl}/icon-building.png`,
  ICON_GOV_BUILDING: `${baseUrl}/icon-gov-building.png`,
  ICON_BUSINESS: `${baseUrl}/icon-business.png`,
  ICON_OVERVIEW: `${baseUrl}/icon-dashboard-overview.svg`,
  ICON_PROJECT_INFO: `${baseUrl}/icon-project-info.svg`,
  ICON_SERVICE_DETAIL: `${baseUrl}/icon-service-detail.svg`,
  ICON_PERFORMANCE: `${baseUrl}/icon-performance.svg`,
  ICON_WRENCH: `${baseUrl}/icon-wrench.svg`,
  ICON_HEADPHONE: `${baseUrl}/icon-headphone.svg`,
  ICON_INBOX: `${baseUrl}/icon-inbox.svg`,
  ICON_CONNECTIVITY: `${baseUrl}/connectivity.svg`,
  ICON_CLOUD: `${baseUrl}/cloud.svg`,
  ICON_FINTECH: `${baseUrl}/fintech.svg`,
  ICON_IOT: `${baseUrl}/iot.svg`,
  ICON_CRM: `${baseUrl}/crm.svg`,
  ICON_SECURITY: `${baseUrl}/security.svg`,
  ICON_BUSINESS_RED: `${baseUrl}/business-red.svg`,
  ICON_BIG_DATA: `${baseUrl}/bigData.svg`,
  ICON_SATELITE: `${baseUrl}/satelite.svg`,
  ICON_APP: `${baseUrl}/app.svg`,
  ICON_ADVERTISING: `${baseUrl}/advertise.svg`,
};

export default ICONS;
