import { Box, ListItem, ListItemText } from "@mui/material";
import styled from "@emotion/styled";
import Text from "@ds/components/Text";
import Link from "next/link";
import type { TContent, TListMenuFooter } from "./type";

const ListContent = styled("ul")(({ maxHeightList }: any) => ({
  position: "relative",
  padding: 0,
  display: "flex",
  flexGrow: 1,
  flexWrap: "wrap",
  maxHeight: maxHeightList,
  flexDirection: "column",
  margin: 0,
}));

const ListMenu: React.FC<TListMenuFooter> = ({
  id,
  title,
  content,
  contentWidth,
  maxWidthList,
  maxHeightList,
}) => {

  return (
    <Box width={contentWidth}>
      <Text variant="h3" css={{ marginBottom: 10 }}>{title}</Text>
      <ListContent maxHeightList={maxHeightList}>
        {content?.map((item: TContent, key: number) => {
          const url = item.url;
          const slug = item.productSlug;
          const disabled = item.disabled === true;
          return (
            <ListItem key={key} sx={{ width: "auto" }} disablePadding>
              <ListItemText
                primary={
                  id === "product-menu" ? (
                    <Link href={`/${slug}`} passHref>
                      <a>
                        <Text
                          clickable
                          variant="body2"
                          weight="400"
                        >
                          {item.productName}
                        </Text>
                      </a>
                    </Link>
                  ) : (
                    <Link href={`/${url}`} passHref>
                      <a style={{ pointerEvents: disabled ? "none" : "auto" }}>
                        <Text
                          clickable={!disabled}
                          variant="body2"
                          weight="400"
                        >
                          {item.productName}
                        </Text>
                      </a>
                    </Link>
                  )
                }
                sx={{ maxWidth: maxWidthList }}
              />
            </ListItem>
          );
        })}
      </ListContent>
    </Box>
  );
};

export default ListMenu;
