import { makeStyles } from "@mui/styles";
import color, { colorMapper } from "@definitions/mui/color";
import type { TFooterProps } from "./type";

const useStyles = makeStyles(() => ({
  box: ({ bgColor, isMobile, isTablet }: TFooterProps) => ({
    borderTop: `1px solid ${color.grey.light}`,
    paddingTop: isMobile ? 28 : 40,
    paddingLeft: isTablet ? 16 : "auto",
    minHeight: "500px",
    backgroundColor: color.grey.mid,
    ...(bgColor && {
      backgroundColor: colorMapper(bgColor),
    }),
  }),
  footer: ({ bgColor }: TFooterProps) => ({
    width: "100%",
    backgroundColor: bgColor === "white" ? color.grey.mid : color.inactive.primary,
    height: "40px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  }),
}));

export default useStyles;
