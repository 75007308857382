import React from "react";
import styled from "@emotion/styled";
import Image from "next/image";
import { Box, BoxProps } from "@mui/material";

interface IDynamicImage extends Omit<BoxProps, "width" | "height"> {
  src: string;
  alt: string;
  width?: number | string;
  height?: number | string;
  quality?: number;
  rounded?: boolean;
  blurDataURL?: string;
  placeholder?: "blur" | "empty";
  priority?: boolean;
}

interface IImageLoaderKit {
  src: string;
  width?: number | string;
  quality?: number;
}

export const DynamicBoxImageRoot = styled(Box)(({ rounded }: any) => ({
  display: "flex",
  ...(rounded && {
    borderRadius: 16,
    overflow: "hidden"
  }),
}));

export const DynamicImageRoot = styled("div")(({ rounded }: any) => ({
  width: "100%",
  overflow: "hidden",
  "> span": {
    position: "unset !important",
  },
  img: {
    objectFit: "contain",
    height: "unset !important",
    width: "100% !important",
    position: "relative !important",
  },
  ...(rounded && {
    borderRadius: 16,
  }),
}));

const ImageKitLoader = ({ src, width, quality }: IImageLoaderKit) => {
  if (src[0] === "/") src = src.slice(1);
  const params = [`w-${width}`];

  if (quality) {
    params.push(`q-${quality || 75}`);
  }
  const paramsString = params.join(",");

  return `${src}?tr=${paramsString}`;
};

const DynamicImage = React.forwardRef(function DynamicImage(props: IDynamicImage, ref) {
  const {
    src,
    alt,
    width,
    height,
    quality,
    placeholder,
    blurDataURL,
    rounded = false,
    priority = false,
    ...otherProps
  } = props;

  const IS_SIZE_DEFINED = width && height;

  if (IS_SIZE_DEFINED) {
    return (
      <DynamicBoxImageRoot rounded={rounded} {...otherProps}>
        <Image
          loader={ImageKitLoader}
          src={src}
          alt={alt}
          blurDataURL={blurDataURL}
          placeholder={placeholder}
          width={width}
          height={height}
          quality={quality}
          priority={priority}
        />
      </DynamicBoxImageRoot>
    );
  }

  return (
    <DynamicImageRoot as={Box} ref={ref} rounded={rounded} {...otherProps}>
      <Image
        loader={ImageKitLoader}
        src={src}
        alt={alt}
        blurDataURL={blurDataURL}
        placeholder={placeholder}
        quality={quality}
        layout="fill"
        priority={priority}
      />
    </DynamicImageRoot>
  );
});

export default DynamicImage;
