import ICONS from "@config/icons";

export const ListExploreMore = [
  {
    url: "bantuan",
    productName: "Bantuan",
  },
  {
    url: "insight",
    productName: "Insight",
  },
  {
    url: "tentang-kami",
    productName: "Tentang Kami",
  },
  {
    url: "kebijakan-privasi",
    productName: "Kebijakan Privasi"
  },
  {
    url: "syarat-dan-ketentuan",
    productName: "Syarat & Ketentuan"
  },
];

export const ListSolusiBisnis = [
  {
    url: "/",
    productName: "Small Medium Enterprise",
    disabled: true,
  },
  {
    url: "/",
    productName: "Enterprise",
    disabled: true,
  },
  {
    url: "/government",
    productName: "Government",
    disabled: false,
  },
];

export const ListSocialMedia = [
  {
    icon: ICONS.ICON_FACEBOOK,
    href: "https://www.facebook.com/TelkomIndonesia",
    alt: "Facebook Telkom Indonesia",
  },
  {
    icon: ICONS.ICON_YOUTUBE,
    href: "https://www.youtube.com/c/TelkomIndonesiaOfficial",
    alt: "Youtube Telkom Indonesia",
  },
  {
    icon: ICONS.ICON_TWITTER,
    href: "https://twitter.com/TelkomIndonesia",
    alt: "Twitter Telkom Indonesia",
  },
  {
    icon: ICONS.ICON_INSTAGRAM,
    href: "https://www.instagram.com/telkomindonesia",
    alt: "Instagram Telkom Indonesia",
  },
];
