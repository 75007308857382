import dayjs from "dayjs";
import Link from "next/link";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import { useMediaQuery, useTheme } from "@mui/material";
import Text from "@ds/components/Text";
import ICONS from "@config/icons";
import { logo } from "@config/images";
import ListMenu from "./components/ListMenu";
import { ListSocialMedia } from "./constant/MenuContent";
import type { ISocialMedia, TFooterProps } from "./type";
import useStyles from "./styles";
import { useTranslation } from "next-i18next";
import DynamicImage from "@ds/components/DynamicImage";
import useResponsive from "@helpers/useResponsive";

const Footer: React.FC<TFooterProps> = ({ bgColor, dataNavbar }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isTablet = useResponsive("lg");
  const classes = useStyles({ bgColor, isMobile, isTablet });
  const { t } = useTranslation("common");
  // const normalizeBusiness = businessSolution.map((item: TNavData) => ({
  //   url: slugValidate(item.type),
  //   productName: item.type,
  // }));
  const ListExploreMore = [
    {
      url: "bantuan",
      productName: t("footer.menu.help"),
    },
    {
      url: "insight",
      productName: t("footer.menu.insight"),
    },
    {
      url: "tentang-kami",
      productName: t("footer.menu.about"),
    },
    {
      url: "kebijakan-privasi",
      productName: t("footer.menu.privacy"),
    },
    {
      url: "syarat-dan-ketentuan",
      productName: t("footer.menu.terms"),
    },
  ];

  return (
    <>
      <Box className={classes.box}>
        <Container maxWidth="lg" disableGutters={isMobile ? false : true}>
          <Grid container justifyContent="space-between">
            <Grid item xs={12} md={5}>
              <Box width={isMobile ? "100%" : 400}>
                <Box mb={4}>
                  <Box>
                    <DynamicImage src={logo} alt="MyTEnS Telkom Indonesia" width={80} height={35} />
                  </Box>
                  <Box>
                    <DynamicImage
                      src={ICONS.ICON_POWERED_TELKOM}
                      alt="Powered by Telkom Indonesia"
                      width={240}
                      height={51}
                    />
                  </Box>
                  <Text
                    paragraph
                    variant="body2"
                    color="inactive-secondary"
                    weight="400"
                    sx={{ marginTop: 16 }}
                  >
                    {t("footer.desc")}
                  </Text>
                </Box>
                <Box mb={4}>
                  <Text component="div" variant="body1" weight="600" color="general-dark">
                    PT Telkom Indonesia (Persero)
                  </Text>
                  <Text paragraph variant="body1" color="inactive-secondary" weight="400">
                    Jl. Jenderal Gatot Subroto Kav. 52 Jakarta 12710 - INDONESIA
                  </Text>
                </Box>
                <Box mb={3}>
                  <Text paragraph variant="body2" color="inactive-secondary" weight="400">
                    {t("footer.follow")}
                  </Text>
                  <Box ml={-1} mb={2}>
                    {ListSocialMedia.map((item: ISocialMedia, idx: number) => {
                      return (
                        <Box display="inline-flex" key={idx}>
                          <Link href={item.href} passHref>
                            <IconButton>
                              <DynamicImage width={30} height={30} src={item.icon} alt={item.alt} />
                            </IconButton>
                          </Link>
                        </Box>
                      );
                    })}
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              md={7}
              display="flex"
              justifyContent="flex-end"
              pl={isMobile ? 0 : 8}
            >
              <Grid container direction="row" spacing={2}>
                <Grid item xs={12} md={8} justifyContent="flex-end" mb={2}>
                  <Box mb={2}>
                    <ListMenu id="product-menu" content={dataNavbar} title={t("footer.product")} />
                  </Box>
                  {/* <Box>
                    <ListMenu
                      id="bisnis-solusi"
                      content={ListSolusiBisnis}
                      maxWidthList={isMobile ? "100%" : 270}
                      title={t("footer.solution")}
                    />
                  </Box> */}
                </Grid>
                <Grid item xs={12} md={4} mb={2}>
                  <ListMenu
                    id="expore-more"
                    content={ListExploreMore}
                    maxWidthList={isMobile ? "100%" : 270}
                    title={t("footer.other")}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box className={classes.footer}>
        <Text sx={{ fontSize: "0.75rem" }}>
          Beta Ver. &copy;<>{dayjs().year()}</> PT Telkom Indonesia Tbk. All right reserved.
        </Text>
      </Box>
    </>
  );
};

export default Footer;
